import { NgModule } from "@angular/core";

// app
import { CoreModule } from "./core/core.module";
import { SharedModule } from "./features/shared/shared.module";
import { AppRoutingModule } from "./app.routing";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Amplify } from "aws-amplify";
import { environment } from "@mypxplat/xplat/core";

let searchParams = new URLSearchParams(window.location.search);
let clientId = environment.cognito.clientId;
if (searchParams.has("client_id") && environment.cognito.appClients[searchParams.get("client_id")]) clientId = environment.cognito.appClients[searchParams.get("client_id")];
let ssoParamsString = localStorage.getItem("myp.sso_params");
if (ssoParamsString) {
  let ssoParams = JSON.parse(ssoParamsString);
  if (ssoParams.client_id && environment.cognito.appClients[ssoParams.client_id]) clientId = environment.cognito.appClients[ssoParams.client_id];
}
Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: clientId,
      userPoolId: environment.cognito.userPoolId,
      loginWith: {
        oauth: {
          providers: ["Google"],
          domain: "presonus-connect-dev.auth.us-east-1.amazoncognito.com",
          scopes: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
          redirectSignIn: [environment.connectUrl + "home"],
          redirectSignOut: [environment.connectUrl + "logout"],
          responseType: "code",
        },
      },
    },
  },
});

@NgModule({
  imports: [CoreModule, SharedModule, AppRoutingModule, BrowserAnimationsModule],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
