import { Component } from "@angular/core";
import { StorageService, UserService, StorageKeys, environment } from "@mypxplat/xplat/core";
import { AppBaseComponent, AppService, AvoService, ReftagService, ScriptService } from "@mypxplat/xplat/web/core";
import { SwitchLanguageComponent } from "@mypxplat/xplat/web/features";
import { TranslateService } from "@ngx-translate/core";
import { takeUntil } from "rxjs";
import * as moment from "moment";

// xplat

@Component({
  selector: "myp-root",
  templateUrl: "./app.component.html",
})
export class AppComponent extends AppBaseComponent {
  constructor(
    userService: UserService,
    public appService: AppService,
    public avoService: AvoService,
    private _storageService: StorageService,
    private _translateService: TranslateService,
    private _scriptService: ScriptService,
    public reftagService: ReftagService
  ) {
    super(userService);
    this.userService.appId = "presonus_connect";
    this.appService.title = "PreSonus Connect";
    this.appService.showLangSwitcher$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.appService.showModal(SwitchLanguageComponent, { size: "lg", ariaLabelledBy: "modal-title" });
    });
  }

  ngOnInit() {
    let languageToUse = "en";
    if (this.user && this.user.language_code) {
      languageToUse = this.user.language_code;
    } else {
      const savedLanguage = this._storageService.getItem(StorageKeys.LANGUAGE);
      if (savedLanguage) {
        languageToUse = savedLanguage;
      }
    }
    if (this.env.production) this._scriptService.loadScript("googletagmanager");
    if (!this.userService.availableLanguages[languageToUse]) languageToUse = "en";
    this.avoService.initAvoFunctions(environment.avo.connectKey, "PreSonus Connect", "1.0.0.");
    this._translateService.setDefaultLang(languageToUse);
    moment.locale(languageToUse);
    this._translateService.use(languageToUse);
  }
}
